import React from 'react';
import PropTypes from 'prop-types';

import JobDetailComp from '../shared/JobDetail';
import MainLayout from '../shared/MainLayout';
import MySEO from '../shared/MySEO';
import { FooterRecruitment } from '../shared/LandingTeacher/FooterRecruitment';
import Footer from '../shared/OurStory/Footer';

const JobDetail = props => {
  const { pageContext } = props;
  const { job } = pageContext;

  return (
    <MainLayout
      hideMobileBottomNav
      hideSideCTA
      noMenu
      noFooter
      showFooterRegister={false}
    >
      <MySEO
        title="Tuyển dụng tại DOL - Cơ hội việc làm hấp dẫn"
        description="Cơ hội trở thành 1 phần của DOL IELTS Đình Lực với quyền lợi cực kỳ hấp dẫn"
        featureImg="Q4QoRUtQs23AuUeS2h8w"
      />
      <JobDetailComp job={job} />
      <FooterRecruitment />
      {/* <Footer /> */}
    </MainLayout>
  );
};

JobDetail.propTypes = {
  pageContext: PropTypes.shape({
    job: PropTypes.shape({})
  }).isRequired
};

export default JobDetail;
